<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <alert-response-warning v-if="state.hasError" :error="state.accessError" />

    <div v-if="!state.hasError" class="flex flex-col sm:flex-row sm:justify-between sm:items-center border-b pb-2">
      <div class="flex justify-between items-center">
        <h2 class="font-bold text-xl sm:text-2xl">
          Disabled Owners List
        </h2>
        <add-link
          :to="{ name: 'SettingsDisabledOwnersCreate' }"
          title="Add"
          class="sm:hidden"
        />
      </div>

      <div class="sm:flex sm:flex-row sm:items-center sm:justify-between sm:divide-x sm:space-x-4 mt-3 sm:mt-0">
        <text-input type="search" v-model="state.search" placeholder="Search..." class="w-full sm:w-64 lg:w-96" />
        <add-link
          :to="{ name: 'SettingsDisabledOwnersCreate' }"
          title="Add"
          class="hidden sm:flex"
        />
      </div>
    </div>

    <div class="bg-concrete rounded-md shadow-sm mt-6 xl:mt-8">
      <div v-if="!state.hasError" class="flex flex-col">
        <v-table :is-loading="state.isLoading">
          <template #header>
            <v-table-header>Email</v-table-header>
            <v-table-header>Machines</v-table-header>
            <v-table-header><span class="sr-only">Actions</span></v-table-header>
          </template>

          <v-table-row v-for="(owner, index) in filteredOwners" :key="index" class=" hover:bg-gray-50">
            <v-table-col class="font-semibold" v-html="highlightInString(owner.email, state.search)" />
            <v-table-col>
              <div v-for="(location, lindex) in owner.locations" :key="lindex">
                <span v-html="highlightInString(location.name, state.search)" />
              </div>
            </v-table-col>
            <v-table-col>
              <div class="text-right space-x-2 flex justify-end items-center">
                <button type="button" @click.stop="remove(owner.email)" class="w-8 h-8 flex items-center text-blumine hover:text-red-600 transition ease-out duration-150 focus:outline-none" title="Delete">
                  <span class="sr-only">delete</span>
                  <span class="material-icons-outlined">delete</span>
                </button>
              </div>
            </v-table-col>
          </v-table-row>

          <v-table-row v-if="filteredOwners.length === 0">
            <v-table-col colspan="3">No Users</v-table-col>
          </v-table-row>
        </v-table>
      </div>
    </div>
  </layout>
</template>

<script>
import { reactive, computed } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import { highlightInString } from '@/helpers/utils.js'
import Layout from '@/layouts/Default.vue'
import SettingsNav from '@/components/settings/Nav'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import TextInput from '@/components/form/Text.vue'
import VTable from '@/components/Table.vue'
import VTableHeader from '@/components/TableHeader.vue'
import VTableRow from '@/components/TableRow.vue'
import VTableCol from '@/components/TableCol.vue'
import AddLink from '@/components/buttons/AddLink.vue'

export default {
  name: 'settings-disabled-owners-index',

  components: {
    Layout,
    AlertResponseWarning,
    TextInput,
    VTable,
    VTableHeader,
    VTableRow,
    VTableCol,
    SettingsNav,
    AddLink,
  },

  setup() {
    const state = reactive({
      owners: [],
      hasError: false,
      accessError: null,
      isLoading: true,
      search: '',
    })

    const getOwners = async () => {
      state.isLoading = true

      try {
        const { data } = await http.get('disabled-owners')
        state.owners = data.currently_disabled
      } catch (e) {
        state.hasError = true
        state.accessError = e
      }

      state.isLoading = false
    }

    const remove = async (email) => {
      if (window.confirm(`Do you really want delete disabled owner ${email}?`)) {
        try {
          await http.delete(`disabled-owners/${email}`)
          state.owners = state.owners.filter(
            (_user) => _user.email !== email
          )
          notification.success('Success', `Disabled Owner <b>${email}</b> has been deleted`)
        } catch (e) {
          notification.error('Error', 'Something went wrong. Please, try again.')
        }
      }
    }

    const filteredOwners = computed(() => {
      if (state.search === '') return state.owners

      return state.owners.filter((user) => {
        return user.email.toLowerCase().includes(state.search.toLowerCase())
          || user.locations.filter((machine) => {
            return (
              machine.name.toLowerCase().includes(state.search.toLowerCase())
            )
          }).length > 0
      })
    })

    getOwners()

    return {
      state,
      filteredOwners,
      highlightInString,
      remove,
    }
  }
}
</script>
